module.exports = {
  baseApiUrl: 'https://api.pn.vg/api/v1/',
  appId: null,
  subscriberId: null,

  _validateAppId: function () {
    if ('string' !== typeof this.appId) {
      console.error('[PN-SDK-Api] Missing appId');
    }
  },

  _validateSubscriberId: function () {
    if ('string' !== typeof this.subscriberId) {
      console.error('[PN-SDK-Api] Missing subscriberId');
    }
  },

  sendRequest: async function (route, method, body) {
    const response = await fetch(this.baseApiUrl + route, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      console.error('[PN-SDK-Api] Bad status code from server.');
      return;
    }
    const responseData = await response.json();
    console.log('[PN-SDK-Api] ### response data', responseData);
    return responseData;
  },

  getAppConfig: async function () {
    this._validateAppId();

    console.log('[PN-SDK-Api] ### sync: Grabbing VAPID public key from API');
    const response = await fetch(
      this.baseApiUrl + 'sync/' + this.appId + '/web',
      {
        method: 'GET',
      }
    );
    if (!response.ok) {
      console.error('[PN-SDK-Api] Bad status code from server.');
      return;
    }
    const responseData = await response.json();
    console.log('[PN-SDK-Api] ### sync: response data', responseData);
    if (!(responseData && responseData.success)) {
      console.error('[PN-SDK-Api] Bad response from server.');
      return;
    }
    return responseData.config;
  },

  createSubscriber: async function (subscriberBody) {
    this._validateAppId();
    let responseData = await this.sendRequest(
      'players',
      'POST',
      subscriberBody
    );
    if (!(responseData && responseData.success)) {
      console.error('[PN-SDK-Api] Bad response from server.');
      return;
    }
    return responseData;
  },

  updateSubscriber: async function (route, method, subscriberBody) {
    let responseData = await this.sendRequest(route, method, subscriberBody);
    if (!(responseData && responseData.success)) {
      console.error('[PN-SDK-Api] Bad response from server.', responseData);
      return;
    }
    return responseData;
  },

  unsubscribeSubscriber: async function (subscriberId) {
    let responseData = await this.sendRequest(`players/${subscriberId}/unsubscribe`, 'POST', {
        appId: this.appId,
    });
    if (!(responseData && responseData.success)) {
      console.error('[PN-SDK-Api] Bad response from server.', responseData);
      return;
    }
    return responseData;
  },

  sendNotification: async function (body) {
    let responseData = await this.sendRequest('notifications', 'POST', body);

    if (!responseData) {
      console.error('[PN-SDK-Api] Bad response from server.');
      return;
    }
    return responseData;
  },

  init: function (appId, baseApiUrl) {
    this.appId = appId;

    if ('undefined' !== typeof baseApiUrl) {
      this.baseApiUrl = baseApiUrl;
    }
  },
};
