const Browser = require('bowser').getParser(window.navigator.userAgent);
const Utils = require('./utils');
const Api = require('./api');

var PushnewsSubscription = window.PushnewsSubscription || {};

let TASKS_TO_PERFORM = [];
if (Array.isArray(PushnewsSubscription)) {
  TASKS_TO_PERFORM = PushnewsSubscription;
}

PushnewsSubscription = {
  SDK_VERSION: 20600,

  /* CLIENT CONFIG */
  config: {
    baseApiUrl: 'https://api.pn.vg/api/v1/',
    appId: null,
    vapidPublicKey: null,
    serviceWorkerPath: '/pushnews-sw.js',
    autoRegister: false
  },

  /* QUEUES */
  TAGS_TO_SEND: {},
  TASKS_TO_PERFORM: TASKS_TO_PERFORM,

  /* EVENTS */
  EVENTS: {
    subscriptionChange: 'subscriptionChange',
    permissionPromptDisplay: 'permissionPromptDisplay',
    notificationPermissionChange: 'notificationPermissionChange',
    popoverCancelClick: 'popoverCancelClick',
    promptDismissed: 'promptDismissed',
  },
  EVENT_LISTENERS: {
    subscriptionChange: [],
    permissionPromptDisplay: [],
    notificationPermissionChange: [],
    popoverCancelClick: [],
    promptDismissed: [],
  },

  push: function (task) {
    this.TASKS_TO_PERFORM.push(task);
    this.processTasks();
  },

  on: function (eventName, functionToExecute) {
    if (PushnewsSubscription.EVENT_LISTENERS.hasOwnProperty(eventName)) {
      PushnewsSubscription.EVENT_LISTENERS[eventName].push(functionToExecute);
    }
  },

  trigger: function (eventName, data) {
    if (PushnewsSubscription.EVENT_LISTENERS.hasOwnProperty(eventName)) {
      console.log(`[PN-SDK-Subscription] ### FIRING EVENT ${eventName} with data:`, data);
      for (
        let i = 0;
        i < PushnewsSubscription.EVENT_LISTENERS[eventName].length;
        ++i
      ) {
        if (
          'function' ===
          typeof PushnewsSubscription.EVENT_LISTENERS[eventName][i]
        ) {
          PushnewsSubscription.EVENT_LISTENERS[eventName][i](data);
        }
        PushnewsSubscription.EVENT_LISTENERS[eventName].splice(i, 1); // remove current index from array
        --i; // Correct the index value
      }
    }
  },

  processTasks: function () {
    this.TASKS_TO_PERFORM.forEach(function (task) {
      const index = PushnewsSubscription.TASKS_TO_PERFORM.indexOf(task);
      PushnewsSubscription.TASKS_TO_PERFORM.splice(index, 1);

      if ('function' === typeof task) {
        console.log('[PN-SDK-Subscription] *** processTasks executing function', task);
        // in this case, a function was passed, let's simply call it
        task();
        return;
      }

      if (!Array.isArray(task)) {
        console.log('[PN-SDK-Subscription] Error: Aborting unknown task format');
      }
      // in this case, the task was passed like this: ['getUserId', function(userId) { ... }]
      const taskName = task[0];
      const taskParameter = task.length > 1 ? task[1] : null;

      console.log(`[PN-SDK-Subscription] *** processTasks task=${taskName}`, taskParameter);
      switch (taskName) {
        case 'init':
          PushnewsSubscription.init(taskParameter);
          break;
        case 'sendTags':
          PushnewsSubscription.sendTags(taskParameter);
          break;
        case 'getUserId':
          if ('function' === typeof taskParameter) {
            const userId = PushnewsSubscription.getUserId();
            taskParameter(userId);
          }
          break;
        case 'registerForPushNotifications':
          PushnewsSubscription.registerForPushNotifications().then(() => {});
          break;
      }
    });
  },

  sendSelfNotification: function (title, body, url, icon) {
    let userId = this.getUserId();
    let requestBody = {
      app_id: Api.appId,
      contents: {
        en: body,
      },
      include_player_ids: [userId],
      isAnyWeb: true,
      headings: {
        en: title,
      },
      url: url,
      chrome_web_icon: icon,
      firefox_icon: icon,
    };
    let response = Api.sendNotification(requestBody);
    console.log('[PN-SDK-Subscription] response', response);
  },

  isPushSupported: function () {
    let whitelistedPlatforms = [
      8 /* Firefox */, 12 /* Edge */, 5 /* ChromeLike */,
    ];

    let blacklistedOs = ['iOS'];

    return (
      whitelistedPlatforms.includes(this.getDeliveryPlatform()) &&
      !blacklistedOs.includes(Browser.getOSName())
    );
  },

  isPushNotificationsEnabled: function (callback) {
    let result = 'granted' === this.checkPermission();
    if ('function' === typeof callback) {
      return callback(result);
    }
    return result;
  },

  /**
   * This function tries to recover a missing localStorage entry 'pnews_subId'.
   *
   * === Why could it be missing?
   * User might have cleared the local storage or it can be migrating from OneSignal do Pushnews
   *
   * === How can we recover it?
   * First try to access the orchestrator-js localStorage entry "pnews_pnid", and then we
   * try the IlabsPush.getSubscriberInformation() function
   */
  tryToRecoverSubscriberId: function() {

    if (null !== this.getUserId()) {
      // we got the subscriberId, no need to recover
      return;
    }

    // try #1: lets see if the subscriberId is stored on localStorage
    let pnId = localStorage.getItem('pnews_pnid');
    if (pnId) {
      try {
        pnId = JSON.parse(pnId);
      } catch (e) {
        pnId = null;
      }
    }

    // try #2: use the orchestrator-js IlabsPush function to try to get the subscriberId
    if (pnId && 'object' === typeof window.IlabsPush && 'function' === typeof window.IlabsPush.getSubscriberInformation) {
      try {
        let subscriberInformation = window.IlabsPush.getSubscriberInformation();
        if (
          'object' === typeof subscriberInformation
          && subscriberInformation.hasOwnProperty('isSubscribed')
          && subscriberInformation.hasOwnProperty('pnId')
          && true === subscriberInformation.isSubscribed
        ) {
          pnId = subscriberInformation.pnId;
        }
      } catch (e) {
        pnId = null;
      }
    }

    // strip the appId because the pnId is in the format: "appId_subscriberId"
    if (pnId) {
      if (2 === pnId.split("_").length) {
        pnId = pnId.split("_")[1];
      } else {
        pnId = null;
      }
    }

    // we did it! we have successfully restored the subscriberId
    if (pnId) {
      localStorage.setItem('pnews_subId', pnId);
    }

  },

  tryToRecoverSubscriberEndpoint: function () {
    console.log('[PN-SDK-Subscription] Trying to recover subscriber endpoint...');
    navigator.serviceWorker.register(`${this.config.serviceWorkerPath}?appId=${this.config.appId}`, {updateViaCache: "none"}).then(function (registration) {
      registration.pushManager.getSubscription().then(function (subscription) {
        PushnewsSubscription.sendSubscriptionToBackEnd(JSON.stringify(subscription), true).then(function (response) {
          PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.subscriptionChange, true);
          PushnewsSubscription.onSession();
        });
      });
    });
  },

  init: function (params) {
    console.log('[PN-SDK-Subscription] ### subscription.init');
    if (false === this.isPushSupported()) {
      console.error('[PN-SDK-Subscription] ### Push Notifications are not supported on this device :(');
      return;
    }

    Utils.extend(PushnewsSubscription.config, params);

    if (!PushnewsSubscription.config.appId) {
      console.error('[PN-SDK-Subscription] ### subscription.init: missing appId');
      return;
    }

    this.tryToRecoverSubscriberId();

    PushnewsSubscription.on(
      PushnewsSubscription.EVENTS.notificationPermissionChange,
      function (change) {
        console.log('[PN-SDK-Subscription] ### notification permission change', change); // {to: "granted|denied|default|prompt"}
        let isSubscribed = 'granted' === change.to;
        if (!isSubscribed) {
          PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.subscriptionChange, false);
        }
      }
    );

    Api.init(this.config.appId, this.config.baseApiUrl);

    // if vapidPublicKey is not set, let's grab it from the API
    if (!PushnewsSubscription.config.vapidPublicKey) {
      Promise.resolve(Api.getAppConfig()).then(function (config) {
        PushnewsSubscription.config.vapidPublicKey = config.hasOwnProperty('vapid_public_key') ? config.vapid_public_key : null;
      });
    }

    let currentPushPermission = this.checkPermission();

    if (
      this.config.vapidPublicKey &&
      true === this.config.autoRegister &&
      'prompt' === currentPushPermission
    ) {
      this.registerForPushNotifications().then((permission) => { console.log('[PN-SDK-Subscription] ### subscription.registerForPushNotifications: finished!', permission)});
    }

    if ('granted' === currentPushPermission) {
      let subscriberEndpoint = localStorage.getItem('pnews_subEndpoint');
      if (!subscriberEndpoint) {
        this.tryToRecoverSubscriberEndpoint();
      } else {
        this.onSession().then(function() { console.log('[PN-SDK-Subscription] ### subscription.onSession: finished!')});
      }
    }
    this.notificationTriggers();
  },

  registerForPushNotifications: async function () {
    console.log('[PN-SDK-Subscription] ### subscription.registerForPushNotifications: started');

    let currentPermission = this.checkPermission();
    if ('granted' === currentPermission) {
        console.log('[PN-SDK-Subscription] ### subscription.registerForPushNotifications: already granted');
        return Promise.resolve('granted');
    }

    PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.permissionPromptDisplay);

    Notification.requestPermission().then(function(permission) {
      console.log('[PN-SDK-Subscription] ### subscription.registerForPushNotifications: permission is', permission);
      switch (permission) {
        case 'default':
          PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.promptDismissed);
          break;
        case 'granted':
          PushnewsSubscription.registerServiceWorker();
          break;
        case 'denied':
        default:
          PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.popoverCancelClick);
          break;
      }

      return permission;
    });

  },

  registerServiceWorker: async function () {
    console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: started');
    try {
      const registration = await navigator.serviceWorker.register(
          `${this.config.serviceWorkerPath}?appId=${this.config.appId}`,
          {updateViaCache: "none"}
      );
      console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker successfully registered.');
      let serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker installing');
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker installed & waiting');
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker active');
      }

      if (serviceWorker) {
        console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker current state', serviceWorker.state);
        if (serviceWorker.state === 'activated') {
          //If push subscription wasn't done yet have to do here
          console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker already activated - Trying to resubscribe...', registration);
          await PushnewsSubscription.subscribeUserToPush(registration);
        }
        serviceWorker.addEventListener('statechange', function (e) {
          console.log('[PN-SDK-Subscription] ### subscription.registerServiceWorker: Service worker statechange: ', e.target.state);
          if (e.target.state === 'activated') {
            // use pushManger for subscribing here.
            console.log('[PN-SDK-Subscription] subscription.registerServiceWorker: Service worker just got activated. now we can subscribe for push notification', registration);
            PushnewsSubscription.subscribeUserToPush(registration);
          }
        });
      }
      return registration;
    } catch (err) {
      console.error('[PN-SDK-Subscription] Unable to register service worker.', err);
    }
  },

  subscribeUserToPush: async function (registration) {
    if (!this.config.vapidPublicKey) {
        console.error('[PN-SDK-Subscription] ### subscription.subscribeUserToPush: missing vapidPublicKey');
        return Promise.reject('Missing vapidPublicKey');
    }
    console.log('[PN-SDK-Subscription] ### subscribeUserToPush.subscribeUserToPush: registration', registration);

    try {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: Utils.urlBase64ToUint8Array(
          this.config.vapidPublicKey
        ),
      };

      const pushSubscription = await registration.pushManager.subscribe(
        subscribeOptions
      );
      return await this.sendSubscriptionToBackEnd(JSON.stringify(pushSubscription));
    } catch (err) {
      console.error('[PN-SDK-Subscription] Subscribe user to push.', err);
    }
  },

  sendSubscriptionToBackEnd: async function (subscription, isRecovery = false) {
    // validate appId
    if (!this.config.appId) {
        console.error('[PN-SDK-Subscription] ### subscription.sendSubscriptionToBackEnd: missing appId');
        return;
    }

    console.log('[PN-SDK-Subscription] sendSubscriptionToBackEnd.subscription', subscription);
    subscription = JSON.parse(subscription);

    // validate endpont and keys from subscription
    if (!subscription || !subscription.hasOwnProperty('endpoint') || !subscription.hasOwnProperty('keys')) {
        console.error('[PN-SDK-Subscription] ### subscription.sendSubscriptionToBackEnd: missing endpoint or keys');
        return;
    }

    let subscriberBody = this.getData(
      this.config.appId,
      subscription.endpoint,
      subscription.keys.auth,
      subscription.keys.p256dh
    );

    if (isRecovery) {
      PushnewsSubscription.TAGS_TO_SEND['__isRecovery'] = true;
    }

    try {
      const responseData = await Api.createSubscriber(subscriberBody);
      console.log('[PN-SDK-Subscription] response data', responseData);
      localStorage.setItem('pnews_subId', responseData.id);
      localStorage.setItem('pnews_subEndpoint', subscription.endpoint);
      localStorage.setItem('pnews_subAuth', subscription.keys.auth);
      localStorage.setItem('pnews_subP256', subscription.keys.p256dh);

      PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.subscriptionChange, true);

      if (Object.keys(PushnewsSubscription.TAGS_TO_SEND).length > 0) {
        console.log(
          '[PN-SDK-Subscription] Processing tags that were received before we had a valid push subscription...',
          PushnewsSubscription.TAGS_TO_SEND
        );
        PushnewsSubscription.sendTags(PushnewsSubscription.TAGS_TO_SEND).then(function() { console.log('[PN-SDK-Subscription] ### subscription.sendTags: finished!'); });
      }
    } catch (err) {
      console.error('[PN-SDK-Subscription] sendSubscriptionToBackEnd.', err);
    }
  },

  onSession: function () {
    // validate userId
    let userId = this.getUserId();
    if (!userId || userId === 'null') {
        console.error('[PN-SDK-Subscription] ### subscription.onSession: missing userId');
        return Promise.reject('Missing userId');
    }

    let subscriberBody = this.getSubscriberBody();
    let url = `players/${userId}/on_session`;
    console.log('[PN-SDK-Subscription] ### subscription.onSession: started');
    return Api.updateSubscriber(url, 'POST', subscriberBody);
  },

  sendTags: function (tags) {
    // validate appId
    if (!PushnewsSubscription.config.appId) {
      console.log('[PN-SDK-Subscription] ### subscription.sendTags: appId is not set');
      return Promise.reject("Missing appId");
    }

    // validate tags
    console.log('[PN-SDK-Subscription] ### subscription.sendTags: started');
    if (!tags || tags.length === 0) {
      console.log('[PN-SDK-Subscription] ### subscription.sendTags: empty tags');
      return Promise.reject("Missing tags");
    }
    for (let index = 0; index < tags.length; index++) {
      if (tags[index] === false) {
        tags[index] = 'false';
      }
    }

    // validate userId
    let userId = this.getUserId();
    if (!userId || userId === 'null') {
      Utils.extend(PushnewsSubscription.TAGS_TO_SEND, tags);
      console.log(
        '[PN-SDK-Subscription] ### subscription.sendTags: tags enqueued to be sent as soon as we got a valid push subscription.',
        PushnewsSubscription.TAGS_TO_SEND
      );
      return Promise.resolve();
    }

    console.log('[PN-SDK-Subscription] ### subscription.sendTags: sending tags to the API', tags);
    let url = `players/${userId}`;
    return Api.updateSubscriber(url, 'PUT', {
      app_id: PushnewsSubscription.config.appId,
      tags: tags,
    }).then(function() {
      PushnewsSubscription.TAGS_TO_SEND = {};
    });
  },

  /**
   * Returns Push Notification current permission
   *
   * @returns {string} prompt, granted, denied, unsupported
   */
  checkPermission: function () {
    let response;
    if ('PushManager' in window) {
      switch (Notification.permission) {
        case 'granted':
          response = 'granted';
          break;
        case 'denied':
          response = 'denied';
          break;
        case 'default':
        default:
          response = 'prompt';
          break;
      }
    } else {
      response = 'unsupported';
    }
    console.log('[PN-SDK-Subscription] ### checkPermission', response);
    return response;
  },

  getUserId: function () {
    const subId = localStorage.getItem('pnews_subId');
    if (!subId && 'undefined' !== subId) {
      console.log('[PN-SDK-Subscription] Visitor is not subscriber yet.');
      return null;
    }
    return subId;
  },

  getLanguage: function () {
    let languageTag = navigator.language;
    if (languageTag) {
      languageTag = languageTag.toLowerCase();
      let languageSubtags = languageTag.split('-');
      return languageSubtags[0].substring(0, 2);
    } else {
      return 'en';
    }
  },

  getDeliveryPlatform: function () {
    if (Browser.is('safari')) {
      return 7; //Safari
    } else if (Browser.is('firefox')) {
      return 8; //Firefox
    } else if (Browser.is('Microsoft Edge')) {
      return 12; //Edge
    } else {
      return 5; //ChromeLike
    }
  },

  getTimezone: function () {
    return new Date().getTimezoneOffset() * -60;
  },

  getDeviceOs: function () {
    const browserVersion = parseInt(String(Browser.getBrowserVersion()), 10);
    return isNaN(browserVersion) ? -1 : browserVersion;
  },

  getData: function (appId, identifier, webAuth, webP256) {
    return {
      appId: appId,
      deviceModel: navigator?.platform || navigator?.userAgentData?.platform || 'Unknown',
      deviceOs: this.getDeviceOs(),
      deviceType: this.getDeliveryPlatform(),
      identifier: identifier,
      language: this.getLanguage(),
      notificationTypes: 1,
      sdk: PushnewsSubscription.SDK_VERSION,
      timezone: this.getTimezone(),
      webAuth: webAuth,
      webP256: webP256,
    };
  },

  getSubscriberBody: function () {
    const subEndpoint = localStorage.getItem('pnews_subEndpoint');
    const subAuth = localStorage.getItem('pnews_subAuth');
    const subP256 = localStorage.getItem('pnews_subP256');
    if (!subEndpoint || !subAuth || !subP256) {
      console.error('[PN-SDK-Subscription] Subscriber information is availabe on local storage.');
    }
    return this.getData(
      PushnewsSubscription.config.appId,
      subEndpoint,
      subAuth,
      subP256
    );
  },

  notificationTriggers: function () {
    if (!'permissions' in navigator) {
      return;
    }

    navigator.permissions
      .query({ name: 'notifications' })
      .then(function (notificationPerm) {
        notificationPerm.onchange = function () {
          if ('denied' === notificationPerm.state) {

            let subId = localStorage.getItem('pnews_subId');
            if (subId) {
              Api.unsubscribeSubscriber(subId);
              localStorage.removeItem("pnews_subId");
              localStorage.removeItem('pnews_subEndpoint');
              localStorage.removeItem('pnews_subAuth');
              localStorage.removeItem('pnews_subP256');
            }

            PushnewsSubscription.trigger(PushnewsSubscription.EVENTS.popoverCancelClick);

          } else {
            PushnewsSubscription.trigger(
              PushnewsSubscription.EVENTS.notificationPermissionChange,
              { to: notificationPerm.state }
            );
          }
        };
      });
  },
};

window.PushnewsSubscription = PushnewsSubscription;
window.PushnewsSubscription.processTasks();
