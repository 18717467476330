/**
 * helper function that mimics jQuery's 'extend' method
 * @url http://stackoverflow.com/a/11197343/269622
 * @url https://stackoverflow.com/questions/11197247/javascript-equivalent-of-jquerys-extend-method/11197343#comment72410735_11197343
 */
function extend() {
  for (let i = 1; i < arguments.length; i++)
    for (let key in arguments[i])
      if (arguments[i].hasOwnProperty(key)) {
        if (
          typeof arguments[0][key] === 'object' &&
          typeof arguments[i][key] === 'object'
        )
          extend(arguments[0][key], arguments[i][key]);
        else arguments[0][key] = arguments[i][key];
      }
  return arguments[0];
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
}

exports.extend = extend;
exports.urlBase64ToUint8Array = urlBase64ToUint8Array;
